<div class="card flex-md-row flex-column">
  <!-- Image -->
  <div class="image-container position-relative">
    <a class="h-100" [routerLink]="[gym.slug]">
      <img
        class="gym-image h-100 w-100"
        [src]="gym.thumbnail?.url || 'assets/custom_images/img-logo-gray.svg'"
        [alt]="gym.thumbnail ? 'Imagem principal da academia' : 'Logo da academia na cor cinza'"
      >
    </a>

    <ng-container *ngIf="gym.activePromotion">
      <div class="bookmark fixed-top">
        <img class="ml-2 mt-2" src="/assets/icons/ic-label-promo.svg" alt="Ícone indicativo de promoções">
      </div>

      <div class="promo-bottom-text position-absolute text-center text-white w-100">
        {{ gym.activePromotion.thumbnailText }}
      </div>
    </ng-container>
  </div>

  <!-- Gym details -->
  <div class="gym-info-container">
    <!-- Name/Button -->
    <div class="row no-gutters pb-2">
      <div class="col-12" [ngClass]="{ 'col-lg-6 pr-0 pr-lg-2': formula }">
        <div class="title-section mb-0 pb-2">
          <a class="text-truncate" [routerLink]="['/academia', gym.slug]">
            {{ gym.contactInfo.name | removeBrand }}
          </a>
        </div>
      </div>

      <ng-container
        *ngTemplateOutlet="EnrollButton; context: { class: formula ? 'pl-1 d-none d-lg-block' : 'd-none' }"
      ></ng-container>
    </div>

    <!-- Contact/hours -->
    <div class="row no-gutters pt-2 pt-md-1">
      <div class="col-12 col-lg-6 pr-lg-2">
        <app-gym-contact-info [contactInfo]="gym.contactInfo" *ngIf="!gym.evoMigratedUnit || contactRO.successful">
          <a
            class="d-flex link-small mr-3 pr-2"
            target="_blank"
            [href]="gym.googleLink"
          >
            <img class="mr-1" src="assets/icons_custom/ic-link-location.svg" alt="Ícone de localização">
            <span>COMO CHEGAR</span>
          </a>
        </app-gym-contact-info>

        <app-loading-content *ngIf="gym.evoMigratedUnit && contactRO.loading"></app-loading-content>
        <div class="pr-2" *ngIf="gym.evoMigratedUnit && contactRO.error">
          <app-error></app-error>
        </div>
      </div>

      <hr class="w-100 d-block d-lg-none">

      <div class="col-12 col-lg-6 align-self-center pl-lg-1">
        <app-gym-business-hours [businessHours]="gym.workingPeriods"></app-gym-business-hours>
      </div>
    </div>

    <!-- Services/Button -->
    <div class="row no-gutters">
      <ng-container *ngIf="!formula">
        <hr class="hr-bottom w-100 d-none d-lg-block">

        <div class="services-separator col-12 col-lg-6">
          <div class="my-2 my-lg-0 d-flex align-items-center">
            <span class="title">{{ serviceLabel }}</span>

            <div class="position-relative" *ngFor="let benefit of benefits">
              <img
                appClickable
                class="benefit-icon ml-2"
                [src]="benefit.icon"
                [popoverFade]="popTemplate"
                alt="Ícone do serviço {{ benefit.name }}"
              >
              <div *ngIf="benefit.free" class="free-indicator ml-2"></div>

              <ng-template #popTemplate>
                <div [innerHtml]="benefit.tooltip"></div>
              </ng-template>
            </div>

            <a
              type="button"
              appClickable
              (click)="showModalBenefits()"
              class="title ml-2"
              *ngIf="hiddenBenefits > 0"
            >
              +{{ hiddenBenefits }}
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngTemplateOutlet="EnrollButton; context: { class: formula ? 'mt-3 d-lg-none' : 'pl-lg-1 d-lg-block' }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #EnrollButton let-class="class">
  <div class="col-12 col-lg-6" [ngClass]="class">
    <a
      class="btn btn-primary w-100"
      type="button"
      [routerLink]="[gym.slug, 'matricule-se']"
    >
      MATRICULE-SE
    </a>
  </div>
</ng-template>
