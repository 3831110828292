import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { map, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { EvoBaseParamsModel, EvoGymResponse, Gym, GymDetailText, GymFilter, GymModel, GymUnitGroupPlan, MembershipModel } from '@models';
import { GymSearchConfig } from '@models/configs';
import { EvoMembershipResponseModel, EvoMembershipsResponseToLegacy, MembershipsResponsesUnionType } from '@models/membership.model';
import { BrandService, EvoGeneralService, EvoGymService, GymService, HeaderService, PlanService, SEOService } from '@services';
import { EvoMembershipService } from '@services/evo/evo-membership.service';
import { RequestObject } from '@utils/request-object.class';


@Component({
  selector: 'app-gym',
  templateUrl: './gym.component.html',
  styleUrls: ['./gym.component.scss'],
})
export class GymComponent implements OnInit, AfterViewInit {

  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;
  @ViewChild('membershipSection', { static: false }) membershipSection: ElementRef<HTMLElement>;

  public readonly BT_KIDS_PLAN = 63;

  public membershipsRO: RequestObject<MembershipsResponsesUnionType, MembershipModel[]>;

  public config: GymSearchConfig;

  public gym: GymModel;
  public gymLegacyRO: RequestObject<Gym>;
  public gymDetailText: GymDetailText;
  public services: GymFilter[];

  public contactInfoRO: RequestObject<EvoGymResponse[]>;

  public imageHeader: string;


  constructor(
    public readonly brandService: BrandService,
    public readonly evoGeneralService: EvoGeneralService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly evoGymService: EvoGymService,
    private readonly evoMembershipService: EvoMembershipService,
    private readonly gymService: GymService,
    private readonly headerService: HeaderService,
    private readonly planService: PlanService,
    private readonly router: Router,
    private readonly seoService: SEOService,
  ) { }

  ngOnInit(): void {
    this.config           = this.activatedRoute.snapshot.data.config.gymSearch;
    this.gymDetailText    = this.activatedRoute.snapshot.data.config.gymDetailText;
    this.services         = this.getTraining();

    this.requestGymLegacy();
  }

  ngAfterViewInit(): void {
    this.refreshHeader();
  }

  getTraining(): GymFilter[] | [] {
    const training = (this.activatedRoute.snapshot.data.groups as GymFilter[]) || [];

    return training.map(specificTraining => ({ ...specificTraining, activityGroup: { id : -1 } }));
  }

  /**
   * Scroll to membership section with a smooth animation.
   *
   * @param delay - *`Optional`* Apply a `ms` delay to scroll. `scrollIntoView` can't scroll to a element if some element is current
   *                             changing his size, so the delay has to be the total of time that took to a element to change his size.
   */
  scrollToMembership(delay = 0): void {
    setTimeout(() => this.membershipSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' }), delay);
  }

  private refreshHeader(): void {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  private getContactInfo(): void {
    this.contactInfoRO = new RequestObject<EvoGymResponse[]>(
      this.evoGymService.getGymsContactInfo(this.getGymUnitIdParam()),
    );

    this.contactInfoRO.observable$.subscribe(response => {
      this.setSEO();

      this.gym.contactInfo = response[0];
    });
  }

  private getGym(): GymModel {
    return {
      ...this.gymService.mapGymToGymModel(this.gymLegacyRO.response),
      coordinates: {
        latitude:  +this.gymLegacyRO.response.address.latitude,
        longitude: +this.gymLegacyRO.response.address.longitude,
      },
    };
  }

  private requestGymLegacy(): void {
    const slug = this.activatedRoute.snapshot.params.slug;

    this.gymLegacyRO = new RequestObject<Gym>(this.gymService.getLegacyGymBySlug(slug));

    this.gymLegacyRO.observable$.subscribe(response => {
      if (!response) {
        this.gymLegacyRO.error = true;
        this.router.navigate(['/academias']);

        return;
      }

      this.gymLegacyRO.response = response;

      this.gym = this.getGym();

      this.imageHeader = this.gymService.getBannerImage(response.photos)?.url;

      this.refreshHeader();
      this.requestMembership();

      if (this.gym.evoMigratedUnit) {
        this.getContactInfo();
      } else {
        this.setSEO();
      }
    });
  }

  private requestMembership(): void {
    if (this.gym.evoMigratedUnit) {
      this.membershipsRO = new RequestObject(this.evoMembershipService.getMemberships({ ...this.getGymUnitIdParam() }));
    } else {
      this.membershipsRO = new RequestObject(this.planService.getActivePlansByGymUnit(this.gymLegacyRO.response));

      this.membershipsRO.observable$ = this.membershipsRO.observable$.pipe(
        map<GymUnitGroupPlan[], EvoMembershipsResponseToLegacy[]>(response => this.evoMembershipService.convertLegacyToEvo(response))
      );
    }

    this.membershipsRO.observable$ = this.membershipsRO.observable$.pipe(
      tap<EvoMembershipResponseModel[] | EvoMembershipsResponseToLegacy[]>(response => {
        this.membershipsRO.response = this.evoMembershipService.mapMembershipsToComponent(response);
      }),
    );

    this.membershipsRO.observable$.subscribe(_ => {
      if (this.router.url.endsWith('matricule-se')) {
        this.scrollToMembership();
      }
    });
  }

  private getGymUnitIdParam(): Pick<EvoBaseParamsModel, 'gymUnitId'> {
    return { gymUnitId: this.gym.id.toString() };
  }

  private setSEO(): void {
    this.seoService.setGeneralSEO({
      ...this.activatedRoute.data,
      windowTitle:  `${environment.seo.DEFAULT_TITLE} - ${this.gym.contactInfo.name}`,
      slug:         this.activatedRoute.snapshot.params.slug,
    }, false);
  }
}
