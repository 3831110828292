export const EvoErrorMessages = {
  400: 'E-mail e/ou senha inválido(s)',
};

export const LoginErrorValidation = {
  InvalidLogin: 'ApiUserNotFoundError',
};

export enum EvoErrorsCode {
  WrongPassword = 400,
}

export const EvoBaseUrls = {
  generalBaseUrl: 'https://evo-totem.w12app.com.br/',
  /** Use `environment.evo.scheduleBaseUrl` to get the base url for this endpoint.
   *  Interpolated param:
   *  - `{{0}}`: The gym code */
  schedule: '/{{0}}/page/landing-page/agenda',
};

/** String to identify which one of the items from `salesPage` should be use to order memberships. */
export const EVO_MEMBERSHIP_ORDER_ID = 'Bodytech venda online';
